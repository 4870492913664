* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 1.5px;
}

#root {
  position: relative;
}

.app {
  display: flex;
  flex-flow: column;
  flex-direction: column;
  font-size: 24px;
  font-family: "Joystix-monospace";
  transition: all ease 0.33ms;
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
  font-size: 12px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.font-weight-b {
  // font-weight: bold;
  font-variation-settings: "wght" 700;
}

.font-weight-9 {
  font-weight: 900;
}

.font-weight-4 {
  font-weight: 400;
}

.color1 {
  color: #FFFFFF;
}

.color2 {
  color: #FFFB00;
}

.color3 {
  color: #460408;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.border-radius-4 {
  border-radius: 4px;
}

a {
  text-decoration: none;
}

.img {
  max-width: 100%;
}

.app-container {
  position: relative;
}

.word-break {
  word-break: break-word;
}

@media only screen and (max-width: 1020px) {

  .container,
  .container2 {
    width: 500px;
  }
}