.MuiAlert-root {
    width: 200px;
}

.loading-view {
    .dialogBg {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        z-index: 999;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        -webkit-tap-highlight-color: transparent;
        transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        .tipDialog2 {
            z-index: 9999;
            width: 400px;
            background: #000000;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
            padding: 80px 20px 30px;

            .goBtnBox {
                width: 200px;
                height: 60px;

                .MuiButton-contained {
                    background-color: transparent;
                    background-image: url(../../assets/images/goBtn.svg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .MuiButton-root {
                    max-height: 60px;
                    line-height: 60px;
                    width: 100%;
                    border-radius: 0;
                    max-width: 100%;
                    font-size: 24px;
                    font-weight: 900;
                    color: #FFFFFF;
                }
            }

        }
    }
}